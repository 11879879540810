<template>
  <div class="main-settings req-temp-container mt-6">
    <div
      v-for="item in settings"
      :key="item.title"
    >
      <link-card :item="item" class="card-style" />
    </div>
  </div>
</template>

<script>
import LinkCard from "./../../../components/LinkCard.vue";
import { settingsList } from "./settingsList.js";
import { mapActions, mapState } from "vuex";

export default {
  components: { LinkCard },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
  
  },
  data() {
    return {
      loggedInUser: "",
      settings: settingsList,
    };
  },
  methods: {
  },
  created() {
    this.loggedInUser = JSON.parse(localStorage.getItem("userInfo"));
  },
};
</script>